import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Land from "./components/land";
import Login from "./components/login";
import SignUp from "./components/register";
import Profile from "./components/profile";
import JobPost from "./components/jobpost";
import ActiveJobs from "./components/activejobs";
import AboutFirm from "./components/aboutfirm";
import RaiseIssue from "./components/raiseissue";
import Payments from "./components/payments"; // Import the Payments component
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "./components/firebase";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Routes>
              <Route path="/" element={<Land />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/jobpost" element={<JobPost />} />
              <Route path="/activejobs" element={<ActiveJobs />} />
              <Route path="/aboutfirm" element={<AboutFirm />} />
              <Route path="/raiseissue" element={<RaiseIssue />} />
              <Route path="/payments" element={<Payments />} /> {/* Add route for Payments */}
            </Routes>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
