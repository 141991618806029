import React, { useEffect, useState } from "react";
import { db, auth } from "./firebase"; // Import Firebase and auth
import { collection, getDocs } from "firebase/firestore";
import "./activejobs.css";

function ActiveJobs() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading state

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        // Get current user from Firebase authentication
        const user = auth.currentUser;
        if (user) {
          const userId = user.uid;
          const jobsCollectionRef = collection(db, `Users/${userId}/Jobs`);
          const querySnapshot = await getDocs(jobsCollectionRef);

          const jobsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setJobs(jobsData);
          setLoading(false); // Set loading to false once data is fetched
        } else {
          // Handle case where user is not authenticated
          console.log("User is not authenticated.");
          setLoading(false); // Set loading to false
        }
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
        // Handle error gracefully, show fallback or error message
        setJobs([]);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchJobs();
  }, []);

  // Demo jobs data for fallback or loading state
  const demoJobs = [
    { id: "demo1", "Job Title": "Loading...", Date: "Loading...", Location: "Loading...", Workplace: "Loading..." },
    { id: "demo2", "Job Title": "Loading...", Date: "Loading...", Location: "Loading...", Workplace: "Loading..." },
    { id: "demo3", "Job Title": "Loading...", Date: "Loading...", Location: "Loading...", Workplace: "Loading..." },
  ];

  return (
    <div className="active-jobs-page">
      <div className="title">Active Jobs</div>
      <div className="jobs-list">
        {loading ? (
          // Show loading state or fallback jobs
          demoJobs.map((job) => (
            <div key={job.id} className="job-item">
              <div className="job-title">{job["Job Title"]}</div>
              <div className="job-details">
                <div>
                  <span className="label">Date of posting:</span>{" "}
                  {job["Date"]}
                </div>
                <div>
                  <span className="label">Location:</span> {job["Location"]}
                </div>
                <div>
                  <span className="label">Workplace:</span>{" "}
                  {job["Workplace"]}
                </div>
              </div>
            </div>
          ))
        ) : jobs.length === 0 ? (
          // Show no jobs message if no jobs found after loading
          <div className="no-jobs-message">No active jobs found.</div>
        ) : (
          // Render fetched jobs
          jobs.map((job) => (
            <div key={job.id} className="job-item">
              <div className="job-title">{job["Job Title"]}</div>
              <div className="job-details">
                <div>
                  <span className="label">Date of posting:</span>{" "}
                  {job["Date"]}
                </div>
                <div>
                  <span className="label">Location:</span> {job["Location"]}
                </div>
                <div>
                  <span className="label">Workplace:</span>{" "}
                  {job["Workplace"]}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default ActiveJobs;
