import React, { useEffect, useState } from 'react';
import { auth, db } from './firebase'; // Adjust the path to your firebase configuration
import { doc, collection, addDoc, onSnapshot } from "firebase/firestore"; // Import necessary Firestore methods

const questions = [
  { label: "Issue", hint: "Enter the ticket name to identify", type: "text" },
  { label: "Category", type: "select", options: ["Technical", "Operations", "Hiring", "Timeline", "Others"] },
  { label: "Priority", type: "select", options: ["High", "Medium", "Low"] },
  { label: "Description", hint: "Describe the issue in detail", type: "text" },
];

function RaiseIssue() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [tickets, setTickets] = useState([]); // State to store tickets
  const [currentTicketIndex, setCurrentTicketIndex] = useState(0); // State to track current ticket being viewed

  useEffect(() => {
    const fetchTickets = () => {
      const user = auth.currentUser;
      if (user) {
        // Reference to the "Tickets" sub-collection
        const ticketsCollectionRef = collection(doc(db, "Issues", user.uid), "Tickets");

        // Listen for real-time updates
        const unsubscribe = onSnapshot(ticketsCollectionRef, (snapshot) => {
          const ticketsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setTickets(ticketsList);
          setCurrentTicketIndex(0); // Reset to the first ticket when the list updates
        });

        // Clean up the subscription on unmount
        return () => unsubscribe();
      }
    };

    fetchTickets();
  }, []); // Empty dependency array to run only on mount

  const handleNext = async () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setLoading(true);

      // Add date and time
      const today = new Date();
      const date = today.toLocaleDateString();
      const time = today.toLocaleTimeString();
      const finalAnswers = { ...answers, Date: date, Time: time };

      // Save data to Firebase
      const user = auth.currentUser;
      if (user) {
        // Create a reference to the user's "Issues" document
        const userIssuesDocRef = doc(db, "Issues", user.uid);

        // Create a reference to the "Tickets" sub-collection under the user's "Issues" document
        const ticketsCollectionRef = collection(userIssuesDocRef, "Tickets");

        // Add a new document to the "Tickets" collection with a random ID
        await addDoc(ticketsCollectionRef, finalAnswers);

        setLoading(false);
        setSubmitted(true);

        // Show confirmation message briefly before resetting form
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          // Reset form
          setCurrentQuestion(0);
          setAnswers({});
          setSubmitted(false);
        }, 2000); // Show confirmation for 2 seconds
      } else {
        alert("User is not authenticated!");
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers({ ...answers, [name]: value });
  };

  const handleNextTicket = () => {
    setCurrentTicketIndex((prevIndex) => (prevIndex + 1) % tickets.length); // Cycle through tickets
  };

  const current = questions[currentQuestion];

  return (
    <div className="raiseissue-page">
      <div className="title">Issue Tickets</div>
      <div className="container-wrapper">
        {/* Left Container */}
        <div className="container left-container">
          <h2>Raised Tickets Status !!</h2>
          <div className="ticket-details">
            {tickets.length > 0 ? (
              <div className="ticket-item">
                <p><strong>Issue:</strong> {tickets[currentTicketIndex].Issue}</p>
                <p><strong>Category:</strong> {tickets[currentTicketIndex].Category}</p>
                <p><strong>Priority:</strong> {tickets[currentTicketIndex].Priority}</p>
                <p><strong>Description:</strong> {tickets[currentTicketIndex].Description}</p>
                <p><strong>Date:</strong> {tickets[currentTicketIndex].Date} <strong>Time:</strong> {tickets[currentTicketIndex].Time}</p>
              </div>
            ) : (
              <p>No tickets raised yet.</p>
            )}
          </div>
          <button
            className="next-button"
            onClick={handleNextTicket}
            disabled={tickets.length === 0}
          >
            Next
          </button>
        </div>

        {/* Separator with OR */}
        <div className="separator">
          <div className="line"></div>
          <div className="or-text">OR</div>
          <div className="line"></div>
        </div>

        {/* Right Container */}
        <div className="container right-container">
          <h2>Create a ticket to raise a new issue, fill out the form below, and we will get in touch</h2>
          {showConfirmation ? (
            <div className="confirmation-message">Issue successfully raised!</div>
          ) : current.type === "text" || current.type === "textarea" ? (
            <>
              {current.type === "textarea" ? (
                <textarea
                  name={current.label}
                  placeholder={current.hint}
                  value={answers[current.label] || ""}
                  onChange={handleChange}
                />
              ) : (
                <input
                  type="text"
                  name={current.label}
                  placeholder={current.hint}
                  value={answers[current.label] || ""}
                  onChange={handleChange}
                />
              )}
            </>
          ) : (
            <>
              <label>{current.label}</label>
              <select
                name={current.label}
                value={answers[current.label] || ""}
                onChange={handleChange}
              >
                <option value="">Select an option</option>
                {current.options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </>
          )}
          <button
            className="next-button"
            onClick={handleNext}
            disabled={loading || submitted}
          >
            {loading ? "Submitting issue..." : submitted ? "Issue submitted" : currentQuestion < questions.length - 1 ? "Next" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RaiseIssue;