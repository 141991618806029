import React, { useState } from "react";
import { auth, db } from "./firebase"; // Ensure Firebase is correctly configured
import { doc, setDoc, collection } from "firebase/firestore";
import "./jobpost.css";

const questions = [
  { label: "Job Title", hint: "Enter the position name, e.g., Software Engineer", type: "text" },
  { label: "About company", hint: "Leave this empty!! We've got you covered", type: "text" },
  { label: "Workplace", type: "select", options: ["Remote", "On-site", "Hybrid"] },
  { label: "Location", hint: "Specify the location, e.g., New York, NY", type: "text" },
  { label: "Job-type", type: "select", options: ["Full Time", "Part Time", "Contract", "Temporary", "Other", "Volunteer"] },
  { label: "Description", hint: "Describe the job and expectations", type: "text" },
  { label: "Skills", hint: "Add skills separated by commas", type: "text" },
  { label: "Degree", type: "select", options: ["Bachelors", "Masters", "Ph.D", "Other"] },
  { label: "Specific Subject", hint: "Optional", type: "text" },
  { label: "Specific certification", hint: "Optional", type: "text" },
  { label: "Any compensation figure", hint: "Optional", type: "text" },
  { label: "Other benefits", hint: "Optional", type: "text" },
  { label: "Website to apply", hint: "Any external website apart from Splixon", type: "text" },
];

function JobPost() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleNext = async () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setLoading(true);

      // Add date and time
      const today = new Date();
      const date = today.toLocaleDateString();
      const time = today.toLocaleTimeString();
      const finalAnswers = { ...answers, Date: date, Time: time };

      // Save data to Firebase
      const user = auth.currentUser;
      if (user) {
        const jobRef = doc(collection(doc(db, "Users", user.uid), "Jobs"));
        await setDoc(jobRef, finalAnswers);
        setLoading(false);
        setSubmitted(true);

        // Show confirmation message briefly before resetting form
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          // Reset form
          setCurrentQuestion(0);
          setAnswers({});
          setSubmitted(false);
        }, 2000); // Show confirmation for 2 seconds
      } else {
        alert("User is not authenticated!");
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers({ ...answers, [name]: value });
  };

  const handleRoughDescriptionSubmit = () => {
    alert("Rough job description submission is not yet implemented.");
  };

  const current = questions[currentQuestion];

  return (
    <div className="jobpost-page">
      <div className="title">Post a new Job</div>
      <div className="container-wrapper">
        {/* Left Container */}
        <div className="container left-container">
          <h2>Just paste the rough job description and relax !!</h2>
          <textarea
            className="text-area"
            placeholder="We've got you covered......... :)"
          ></textarea>
          <button
            className="submit-button"
            onClick={handleRoughDescriptionSubmit}
          >
            Submit
          </button>
        </div>

        {/* Separator with OR */}
        <div className="separator">
          <div className="line"></div>
          <div className="or-text">OR</div>
          <div className="line"></div>
        </div>

        {/* Right Container */}
        <div className="container right-container">
          <h2>Fill each field separately</h2>
          {showConfirmation ? (
            <div className="confirmation-message">Job successfully posted!</div>
          ) : current.type === "text" ? (
            <>
              <label>{current.label}</label>
              <input
                type="text"
                name={current.label}
                placeholder={current.hint}
                value={answers[current.label] || ""}
                onChange={handleChange}
              />
            </>
          ) : (
            <>
              <label>{current.label}</label>
              <select
                name={current.label}
                value={answers[current.label] || ""}
                onChange={handleChange}
              >
                <option value="">Select an option</option>
                {current.options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </>
          )}
          <button
            className="next-button"
            onClick={handleNext}
            disabled={loading || submitted}
          >
            {loading ? "Posting the job..." : submitted ? "Job posted" : currentQuestion < questions.length - 1 ? "Next" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default JobPost;
