import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./profile.css";

function Profile() {
  const [userDetails, setUserDetails] = useState(null);
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const docRef = doc(db, "Users", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setUserDetails(docSnap.data());
          } else {
            console.log("User is not logged in");
          }
        }
      });
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesCollectionRef = collection(db, "Services");
        const querySnapshot = await getDocs(servicesCollectionRef);
        const servicesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServices(servicesData);
      } catch (error) {
        console.error("Error fetching services:", error.message);
        const demoServices = [
          { id: "demo1", name: "Demo Service 1", image: "demo1.jpg" },
          { id: "demo2", name: "Demo Service 2", image: "demo2.jpg" },
          { id: "demo3", name: "Demo Service 3", image: "demo3.jpg" },
          { id: "demo4", name: "Company Profile", image: "demo4.jpg" },
          { id: "demo5", name: "Demo Service 5", image: "demo5.jpg" },
          { id: "demo6", name: "Raise Issue", image: "demo6.jpg" },
          { id: "demo7", name: "Cards and Bill", image: "demo7.jpg" }, // Added Cards and Bill
        ];
        setServices(demoServices);
      }
    };

    fetchServices();
  }, []);

  async function handleLogout() {
    try {
      await auth.signOut();
      navigate("/login");
      console.log("User logged out successfully!");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  }

  const handleServiceClick = (index) => {
    if (index === 1) {
      navigate("/activejobs");}
    else if (index === 2) {
      alert("Currently no shorlisted candidates :) !!");
    } else if (index === 3) {
      navigate("/aboutfirm");
    } else if (index === 4) {
      alert("This can be accessed only by organisation admin :) !!");
    } else if (index === 5) {
      navigate("/raiseissue");
    } else if (index === 6) {
      navigate("/payments");
    } else {
      navigate("/jobpost");
    }
  };

  return (
    <div className="profile-page">
      <div className="header">
        <div className="company-name">SPLIXON</div>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className="container-wrapper">
        {services.map((service, index) => (
          <div
            className="square-container"
            key={service.id}
            onClick={() => handleServiceClick(index)}
            style={{ cursor: "pointer" }}
          >
            <div className="content">
              <img
                className="container-image"
                src={service.image}
                alt={service.name}
              />
              <p>{service.name}</p>
            </div>
          </div>
        ))}

        {/* Fallback or demo containers */}
        {services.length === 0 &&
          [1, 2, 3, 4, 5, 6].map((index) => (
            <div
              className="square-container"
              key={`demo-${index}`}
              onClick={() => handleServiceClick(index)}
              style={{ cursor: "pointer" }}
            >
              <div className="content">
                <img
                  className="container-image"
                  src={`demo${index}.jpg`}
                  alt={`Demo Service ${index}`}
                />
                <p>{`Demo Service ${index}`}</p>
              </div>
            </div>
          ))}
      </div>

      <hr className="line" />

      <div className="additional-content">
        <div className="additional-container square-container-small">
          <p>View Demo Setup !!!</p>
        </div>
        <div className="additional-container rectangle-container">
          <h2>SPLIXON is plug and play for hiring.</h2>
          <p>View our documentation</p>
        </div>
      </div>
    </div>
  );
}

export default Profile;
