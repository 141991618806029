import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth, db } from "./firebase";
import { setDoc, doc, collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import "./register.css"; // Import the updated CSS file

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Register the user
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      console.log(user);

      if (user) {
        // Add user information to the "Users" collection
        await setDoc(doc(db, "Users", user.uid), {
          email: user.email,
          firstName: fname,
          lastName: lname,
          photo: ""
        });

        // Create a document in the "Issues" collection with the user's UID
        const userIssuesDoc = doc(db, "Issues", user.uid);
        await setDoc(userIssuesDoc, {});

        // Add a random document to the "Tickets" sub-collection under the user's "Issues" document
        const ticketsCollectionRef = collection(userIssuesDoc, "Tickets");
        await addDoc(ticketsCollectionRef, {
          // Add initial data if needed, e.g., a placeholder or welcome message
          title: "Welcome Ticket",
          description: "This is a placeholder ticket.",
          createdAt: new Date().toISOString()
        });
      }

      console.log("User Registered Successfully!!");
      toast.success("User Registered Successfully!!", {
        position: "top-center",
      });
    } catch (error) {
      console.log(error.message);
      toast.error(error.message, {
        position: "bottom-center",
      });
    }
  };

  return (
    <form onSubmit={handleRegister} className="register-form">
      <h3>Sign Up</h3>

      <div className="mb-3">
        <label>First name</label>
        <input
          type="text"
          className="form-control"
          placeholder="First name"
          onChange={(e) => setFname(e.target.value)}
          required
        />
      </div>

      <div className="mb-3">
        <label>Last name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Last name"
          onChange={(e) => setLname(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <label>Email address</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="mb-3">
        <label>Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>

      <div className="d-grid">
        <button type="submit" className="btn btn-primary">
          Sign Up
        </button>
      </div>

      <p className="forgot-password text-right" style={{ fontSize: '12px', textAlign: 'right' }}>
        Already registered <a href="/login">Login</a>
      </p>
    </form>
  );
}

export default Register;
