import React, { useState, useEffect } from "react";
import axios from "axios";
import { auth, db, storage } from "./firebase";
import { doc, getDoc, updateDoc, collection, addDoc, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./aboutfirm.css";

const stockcompanyName = "GOOGL"; // Stock ticker symbol

function AboutFirm() {
  const [userDetails, setUserDetails] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [foundedYear, setFoundedYear] = useState("");
  const [service, setService] = useState("");
  const [website, setWebsite] = useState("");
  const [aboutText, setAboutText] = useState("");
  const [newsItems, setNewsItems] = useState(["", "", ""]);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [stockPrice, setStockPrice] = useState(null);
  const [loadingStock, setLoadingStock] = useState(true); // Loading state for stock price
  const [keyPeople, setKeyPeople] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const docRef = doc(db, "Users", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUserDetails(userData);
            setCompanyName(userData.firstName || "");
            setFoundedYear(userData.foundingyear || "");
            setService(userData.service || "");
            setWebsite(userData.website || "");
            setAboutText(userData.aboutText || "");
            setNewsItems(userData.newsItems || ["", "", ""]);
            setImageUrl(userData.photo || "");

            // Fetch stock price
            fetchStockPrice(stockcompanyName);

            // Fetch key people from Firebase
            const keyPeopleRef = collection(db, `Users/${user.uid}/people`);
            const keyPeopleSnapshot = await getDocs(keyPeopleRef);
            const keyPeopleData = keyPeopleSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setKeyPeople(keyPeopleData);
          } else {
            console.log("User is not logged in");
          }
        }
      });
    };

    fetchUserData();
  }, []);

  const fetchStockPrice = async (companyNamee) => {
    try {
      const apiKey = "YOUR_ALPHA_VANTAGE_API_KEY"; // Replace with your Alpha Vantage API key
      const apiUrl = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${companyNamee}&apikey=${apiKey}`;
      const response = await axios.get(apiUrl);
      const latestTradingDay = Object.keys(response.data["Time Series (Daily)"])[0]; // Get the latest trading day
      const latestPrice = response.data["Time Series (Daily)"][latestTradingDay]["4. close"]; // Get the latest closing price
      setStockPrice(latestPrice); // Set the state with the latest price
      setLoadingStock(false); // Set loading state to false after data is fetched
    } catch (error) {
      console.error("Error fetching stock data:", error);
      setLoadingStock(false); // Ensure loading state is set to false in case of error
    }
  };
  

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      handleUpload(e.target.files[0]); // Automatically upload the image when selected
    }
  };

  const handleUpload = async (file) => {
    setLoading(true);
    const storageRef = ref(storage, `Users/${auth.currentUser.uid}/profile.jpg`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    setImageUrl(url); // Set the image URL after uploading
    setLoading(false);
  };

  const handleSubmit = async () => {
    const docRef = doc(db, "Users", auth.currentUser.uid);
    await updateDoc(docRef, {
      firstName: companyName,
      foundingyear: foundedYear,
      service: service,
      website: website,
      aboutText: aboutText,
      newsItems: newsItems,
      photo: imageUrl,
      keyPeople: keyPeople,
    });
    console.log("Document updated successfully!");
  };

  const handleNewsChange = (index, value) => {
    const newNewsItems = [...newsItems];
    newNewsItems[index] = value;
    setNewsItems(newNewsItems);
  };

  const handleAddPerson = () => {
    if (keyPeople.length < 10) {
      const newPerson = {
        id: keyPeople.length + 1,
        name: "",
        designation: "",
        imageUrl: "",
      };
      setKeyPeople([...keyPeople, newPerson]);
    } else {
      alert("Maximum 10 people can be added.");
    }
  };

  const handleDeletePerson = async (personId) => {
    try {
      await db.collection(`Users/${auth.currentUser.uid}/people`).doc(personId).delete();
      const updatedPeople = keyPeople.filter((person) => person.id !== personId);
      setKeyPeople(updatedPeople);
    } catch (error) {
      console.error("Error deleting person:", error);
    }
  };

  const handlePersonImageChange = async (personId, file) => {
    try {
      const storageRef = ref(storage, `Users/${auth.currentUser.uid}/people/${personId}.jpg`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      const updatedPeople = keyPeople.map((person) =>
        person.id === personId ? { ...person, imageUrl: url } : person
      );
      setKeyPeople(updatedPeople);
    } catch (error) {
      console.error("Error uploading person image:", error);
    }
  };

  const handleAddPersonToFirebase = async (person) => {
    try {
      const docRef = collection(db, `Users/${auth.currentUser.uid}/people`);
      const newPersonRef = await addDoc(docRef, person);
      console.log("Person added to Firebase with ID: ", newPersonRef.id);
    } catch (error) {
      console.error("Error adding person to Firebase: ", error);
    }
  };

  return (
    <div className="about-firm-page">
      <div className="left-side">
        <h2>Company Details</h2>
        <div className="image-container">
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="Company Logo"
              className="company-photo"
              onClick={() => document.getElementById("fileInput").click()}
            />
          ) : (
            <div
              className="fake-loader"
              onClick={() => document.getElementById("fileInput").click()}
            >
              Click to Upload Image
            </div>
          )}
          <input
            id="fileInput"
            type="file"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </div>
        <div className="fields">
          <div className="field">
            <label>Company Name:</label>
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="input-field"
            />
          </div>
          <div className="field">
            <label>Founded Year:</label>
            <input
              type="text"
              value={foundedYear}
              onChange={(e) => setFoundedYear(e.target.value)}
              className="input-field"
            />
          </div>
          <div className="field">
            <label>Service:</label>
            <input
              type="text"
              value={service}
              onChange={(e) => setService(e.target.value)}
              className="input-field"
            />
          </div>
          <div className="field">
            <label>Website:</label>
            <input
              type="text"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              className="input-field"
            />
          </div>
          <button onClick={handleSubmit} className="submit-button">
            Save Changes
          </button>
        </div>
      </div>
      <div className="right-side">
        <h2>About the Company</h2>
        <textarea
          className="text-area"
          value={aboutText}
          onChange={(e) => setAboutText(e.target.value)}
        ></textarea>
        <button onClick={handleSubmit} className="submit-button">
          Save Description
        </button>
        <h3>Stock News</h3>
        {loadingStock ? (
          <div className="news-item">
            <p>Loading stock data...</p>
          </div>
        ) : (
          <div className="news-item">
            <p>Stock Price: ${stockPrice}</p>
          </div>
        )}

        <h3>Key People</h3>
        <div className="key-people-container">
          {keyPeople.map((person, index) => (
            <div className="person-card" key={person.id}>
              {person.imageUrl ? (
                <img
                  src={person.imageUrl}
                  alt={person.name}
                  className="person-photo"
                  onClick={() => document.getElementById(`fileInput${person.id}`).click()}
                />
              ) : (
                <div
                  className="fake-loader"
                  onClick={() => document.getElementById(`fileInput${person.id}`).click()}
                >
                  Click to Upload Image
                </div>
              )}
              <input
                id={`fileInput${person.id}`}
                type="file"
                onChange={(e) => handlePersonImageChange(person.id, e.target.files[0])}
                style={{ display: "none" }}
              />
              <div className="person-details">
                <input
                  type="text"
                  placeholder="Name"
                  value={person.name}
                  onChange={(e) =>
                    setKeyPeople((prevPeople) =>
                      prevPeople.map((p) =>
                        p.id === person.id ? { ...p, name: e.target.value } : p
                      )
                    )
                  }
                  className={`person-field ${person.name && "non-editable"}`}
                  readOnly={!!person.name} // Make field readonly if person's name is retrieved
                  style={{ backgroundColor: person.name ? "#000" : "#222", color: "#fff" }}
                />
                <input
                  type="text"
                  placeholder="Designation"
                  value={person.designation}
                  onChange={(e) =>
                    setKeyPeople((prevPeople) =>
                      prevPeople.map((p) =>
                        p.id === person.id ? { ...p, designation: e.target.value } : p
                      )
                    )
                  }
                  className={`person-field ${person.designation && "non-editable"}`}
                  readOnly={!!person.designation} // Make field readonly if person's designation is retrieved
                  style={{ backgroundColor: person.designation ? "#000" : "#222", color: "#fff" }}
                />
                {(!person.name || !person.designation) && (
                  <>
                    <button onClick={() => handleDeletePerson(person.id)} className="delete-button">
                      Delete
                    </button>
                    <button onClick={() => handleAddPersonToFirebase(person)} className="add-button">
                      Add to Firebase
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <button onClick={handleAddPerson} className="add-person-button">
          Add Person
        </button>
      </div>
    </div>
  );
}

export default AboutFirm;

