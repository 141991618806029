import React, { useState } from "react";
import "./payments.css";

function Payments() {
  const [companyName, setCompanyName] = useState("");
  const [foundedYear, setFoundedYear] = useState("");
  const [service, setService] = useState("");
  const [website, setWebsite] = useState("");
  const [aboutText, setAboutText] = useState("");

  const handleCompanyNameClick = () => {
    setAboutText(`You are currently on unlimited trial plan, enjoy your trial and do let us know if you face any issue by raising the issue. Help us to make better !!`);
  };

  const handleFoundedYearClick = () => {
    setAboutText(`API Documentation 

1. Authentication
Endpoint: /auth
Description: Allows users to authenticate and log in.
Methods:
POST: Initiates authentication process.
GET: Retrieves current authentication status.

2. User Data
Endpoint: /user/{userId}
Description: Retrieves user details based on user ID.
Methods:
GET: Fetches user data from the database.

3. Services
Endpoint: /services
Description: Retrieves a list of services available.
Methods:
GET: Fetches all services.
POST: Adds a new service (not implemented in the current demo).

4. Navigation
Endpoint: /navigate/{path}
Description: Handles navigation to different routes based on user interaction.
Methods:
POST: Navigates the user to the specified route.
Example API Requests and Responses
Authentication

Request:
http
Copy code
POST /auth
Content-Type: application/json

{
  "email": "user@example.com",
  "password": "password"
}

Response:
json
{
  "status": "success",
  "message": "User authenticated successfully."
}
User Data
Request:

http
Copy code
GET /user/{userId}
Response:

json
Copy code
{
  "userId": "abc123",
  "displayName": "John Doe",
  "email": "john.doe@example.com"
}
Services
Request:

http
Copy code
GET /services
Response:

json
Copy code
[
  {
    "id": "demo1",
    "name": "Demo Service 1",
    "image": "demo1.jpg"
  },
  {
    "id": "demo2",
    "name": "Demo Service 2",
    "image": "demo2.jpg"
  },
  // More services...
]
Navigation
Request:

http
POST /navigate
Content-Type: application/json

{
  "path": "/activejobs"
}
Response:

json
{
  "status": "success",
  "message": "Navigated to /activejobs."
}
Explanation
Authentication: Handles user login/logout operations.
User Data: Retrieves user information from the database.
Services: Fetches available services, either from Firestore or demo data if unavailable.
Navigation: Facilitates routing based on user interaction with service containers.
Notes
This documentation provides a high-level overview of the API endpoints based on the functionality of the Profile component.
Ensure to document error handling and edge cases in the API documentation.
The actual implementation will depend on your backend setup and database structure, especially for endpoints like authentication and fetching user data.
`);
  };

  const handleServiceClick = () => {
    setAboutText(`Get in contact with developer team to know about our models.`);
  };



  return (
    <div className="about-firm-page">
      <div className="left-side">
        <h2>Payment Details</h2>
        <div className="fields">
          <div className="field">
            <label onClick={handleCompanyNameClick}>Billing</label>
          </div>
          <div className="field">
            <label onClick={handleFoundedYearClick}>Developer Guide</label>
          </div>
          <div className="field">
            <label onClick={handleServiceClick}>Our Models</label>
          </div>
        </div>
      </div>
      <div className="right-side">
        <h2></h2>
        <textarea
          className="text-area"
          value={aboutText}
          onChange={(e) => setAboutText(e.target.value)}
        ></textarea>
      </div>
    </div>
  );
}

export default Payments;
