import React, { useEffect, useRef } from 'react';
import './land.css';
import { useNavigate } from 'react-router-dom';

const Land = () => {
  const navigate = useNavigate();
  const trailRef = useRef(null);
  const trailElements = useRef([]);
  const maxTrailElements = 250; // Limit the number of trail elements

  useEffect(() => {
    const trailContainer = trailRef.current;
    let lastMouseX = 0;
    let lastMouseY = 0;
    let lastTimestamp = 0;

    // Pre-create trail elements for pooling
    for (let i = 0; i < maxTrailElements; i++) {
      const trailElement = document.createElement('div');
      trailElement.className = 'cursor-trail';
      trailElement.style.display = 'none';
      trailContainer.appendChild(trailElement);
      trailElements.current.push(trailElement);
    }

    let trailIndex = 0; // Current index in the trail pool

    const createTrail = (x, y, color) => {
      const trailElement = trailElements.current[trailIndex];
      trailElement.style.left = `${x}px`;
      trailElement.style.top = `${y}px`;
      trailElement.style.backgroundColor = color;
      trailElement.style.opacity = '1';
      trailElement.style.display = 'block';

      // Fade out trail element
      requestAnimationFrame(() => {
        setTimeout(() => {
          trailElement.style.opacity = '0';
        }, 200);
      });

      // Move to the next element in the pool
      trailIndex = (trailIndex + 1) % maxTrailElements;
    };

    const handleMouseMove = (e) => {
      const currentMouseX = e.clientX;
      const currentMouseY = e.clientY;
      const currentTime = performance.now();

      // Calculate speed
      const deltaX = currentMouseX - lastMouseX;
      const deltaY = currentMouseY - lastMouseY;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const timeElapsed = currentTime - lastTimestamp;
      const speed = distance / timeElapsed;

      // Determine color based on speed
      let color = 'rgba(50, 222, 132, 0.5)'; // Green with 50% opacity
      if (speed > 0.5) color = 'rgba(0, 0, 255, 0.3)'; // Blue with 30% opacity
      if (speed > 1) color = 'rgba(255, 0, 0, 0.3)'; // Red with 30% opacity
      if (speed > 2) color = 'rgba(255, 255, 255, 0.3)'; // White with 30% opacity

      // Bézier curve control points
      const controlX = (lastMouseX + currentMouseX) / 2 + deltaY / 4; // Midpoint + perpendicular offset
      const controlY = (lastMouseY + currentMouseY) / 2 - deltaX / 4;

      const steps = Math.max(1, Math.ceil(distance / 3)); // Reduced division factor for more steps

      for (let i = 0; i <= steps; i++) {
        const t = i / steps;
        // Quadratic Bézier formula
        const interpX = (1 - t) * (1 - t) * lastMouseX + 2 * (1 - t) * t * controlX + t * t * currentMouseX;
        const interpY = (1 - t) * (1 - t) * lastMouseY + 2 * (1 - t) * t * controlY + t * t * currentMouseY;
        createTrail(interpX, interpY, color);
      }

      lastMouseX = currentMouseX;
      lastMouseY = currentMouseY;
      lastTimestamp = currentTime;
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleJoinUs = () => {
    navigate('/login');
  };

  return (
    <div className="landing-page">
      <div className="trail-container" ref={trailRef}></div>
      <div className="landing-content">
        <h1 className="company-name">SPLIXON</h1>
        <button className="join-button" onClick={handleJoinUs}>Join Us</button>
      </div>
    </div>
  );
};

export default Land;
